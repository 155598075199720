import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="columns is-multiline">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="is-parent column is-6" key={post.id}>
              <article className="tile is-child box notification">
                <div className="columns">
                  <div className="column is-4 is-one-third-mobile blog-main-image">
                    <Link
                        className="title is-size-4"
                        to={'https://inbound.human.marketing' + (post.fields.slug).replace("/blog/","/").replace("-archive","")}
                      >
                      <PreviewCompatibleImage imageInfo={post.frontmatter.image} />
                    </Link>
                  </div>
                  <div className="column is-8 is-two-thirds-mobile blog-main-text">
                    <h3>
                      <Link
                        className="title is-size-4"
                        to={'https://inbound.human.marketing' + (post.fields.slug).replace("/blog/", "/").replace("-archive", "")}
                      >
                        {post.frontmatter.heading}
                      </Link>
                      
                    </h3>
                    <p className="blog-main-subtitle">{post.frontmatter.description}</p>
                    <p className="blog-post-exerpt">
                      {post.excerpt}
                      <br />
                      <Link className="button" to={'https://inbound.human.marketing' + (post.fields.slug).replace("/blog/", "/").replace("-archive", "")}>
                        Read Article →
                      </Link>
                    </p>
                  </div>
                </div>                
              </article>
            </div>
          ))}
      </div>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollHomeQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          limit: 6
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 200)
              id
              fields {
                slug
              }
              frontmatter {
                title
                heading
                description
                image {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }  
                templateKey
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
