import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const TwoColumnGrid = ({ gridItems }) => {
  return (    
    <div className="columns is-multiline">         
        {gridItems.map((item, i) => (
          <div key={i + item.text} className="column is-6 casestudy">          
            <section className="columns">
              <div className="has-text-centered column is-5">
                <div className="image__wrapper"
                  style={{
                    maxWidth: '400px',
                    width: '100%',
                    display: 'inline-block',
                  }}
                >
                  <PreviewCompatibleImage imageInfo={item} />
                </div>
              </div>
              <div className="column is-6 two-column--text">
                <div>                  
                  <div className="intro__h2" dangerouslySetInnerHTML={{ __html: item.header }} />
                  
                  <div className="two-column--text-body">                    
                      {item.text}
                  </div>
                  <div className="cta-btn cta-btn__secondary">
                    <Link to={`${item.linkurl}`} >{item.linktitle}</Link> 
                  </div>
                </div>
              </div>
            </section>                    
          </div>
      ))}
    </div>
  )
}

TwoColumnGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default TwoColumnGrid
