import React from 'react'
import { Spring } from 'react-spring/renderprops'

const SlideDown = ({ props }) => {    
    return (                         
        <Spring from={{ opacity: 0, marginTop: -1000 }} to={{ opacity: 1, marginTop: 0 }}>
        { props => (
          <div className="App" style={ props }>
                <div>{props.children}</div>
            </div>
          )
        }
        </Spring>
    )  
}

export default SlideDown