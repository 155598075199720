import React, {useState} from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import { Waypoint } from 'react-waypoint'
import { animated, useSpring, config } from 'react-spring'

const IconsGridHeader = ({ gridItems }) => {  
  const {icons} = gridItems;
  const [on, toggle] = useState(false);
  const fadeIn = useSpring({
    opacity: on ? 1 : 0,
    transform: on ? 'translate3d(0,0,0)' : 'translate3d(0, 50%, 0)',
    config: config.slow
  })
  return (
    <div className="columns is-multiline" 
        style={{
          display: `flex`
    }}>
      <Waypoint 
        bottomOffset="40%"
        onEnter={() => { 
          if (!on) toggle(true);
        }}         
      />
      
      {icons.map( (item, i) => (
        <div key={i} className="column is-4 is-half-mobile icons-grid-section">
          <section >         
              <animated.div className="icons-grid__wrapper" style={fadeIn}>
                <div className="image__wrapper"
                  style={{
                    width: '40px',
                    display: 'inline-block',
                    height: '40px'
                  }}
                >
                  <PreviewCompatibleImage imageInfo={item} />
                </div>
                <h3 className="item-text">{item.header}</h3>
                <p className="item-text">{item.text}</p>
              </animated.div>
          </section>
        </div>
      ))}
    </div>
  )
}

IconsGridHeader.propTypes = {
  gridItems: PropTypes.shape({
    heading: PropTypes.string, 
    icons: PropTypes.array
  }),
}

export default IconsGridHeader
