import React, { useState } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import FourColumnGrid from '../components/FourColumnGrid'
import IconsGridHeader from '../components/IconsGridHeader'
import BlogRollHome from '../components/BlogRollHome'
import SlideDown from '../components/Scroll'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import PreviewCompatibleBgImage from '../components/PreviewCompatibleBgImage'
import VideoModal from '../components/VideoModal'

/* eslint-disable */

export const IndexPageTemplate = ({
  title,
  metadescription,
  image,
  mainpitch,
  casestudy,
  iconsgridheader,
  iconsgridbg,
  helmet,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  return <>
    <div className="index-pg dark-bg-offset">
      {helmet || ''}
      <div
        className="full-width-image margin-top-0 hero-bg"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          backgroundPosition: `bottom left`,
          justifyContent: 'left',
          flexWrap: `wrap`
        }}
      >
        <div className="container">
          <div className="hero-text"
            style={{
              display: 'flex',
              lineHeight: '1',
              alignItems: 'left',
              flexDirection: 'column',
            }}
          >

            <div className="columns">
              <div className="column is-5 main-text" style={{alignSelf:'center'}}>
                <div>
                  <SlideDown />
                  <h1
                    className="has-text-weight-bold"
                    style={{
                      color: 'white',
                      lineHeight: '1.2',
                      padding: '0.25em',
                    }}
                  >{mainpitch.heading}</h1>
                  <p className="subtitle">{mainpitch.description}</p>
                  <div className="btn-wrapper">
                    <a className="has-text-weight-bold btn yellow-btn" href="https://inbound.human.marketing/meetings/joel100/personalize-demo" rel="noopener noreferrer">SCHEDULE A DEMO</a>
                  </div>
                  <div className="btn-wrapper" style={{ marginTop: '5px' }}>
                    <a href="https://personalize.humanautomation.ai/" className="has-text-weight-bold btn clear-btn" rel="noopener noreferrer">
                      {mainpitch.button}
                    </a>
                  </div>
                </div>
              </div>
              <div className="column is-offset-1 is-6">
                <div className="mainpitch-heroimage" onClick={() => setModalIsOpen(true)}>
                  <PreviewCompatibleImage imageInfo={mainpitch.heroimage} />
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
      <section className="section section--gradient">
        <div className="container">
          <div className="section" style={{padding: '0'}}>
            <div className="columns">
              <div className="column is-12">
                <div className="content hm-page">
                  <div className="columns casestudy-section">
                    <div className="column is-12">
                      <FourColumnGrid gridItems={casestudy} />
                    </div>
                  </div>

                  <PreviewCompatibleBgImage
                    bgTag="div"
                    bgClass="hm-full-width intro-section-wrapper icons-grid"
                    imageInfo={iconsgridbg}
                    style={{
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      marginBottom: '60px',
                      minHeight: '700px'
                    }}>
                    <div className="columns">
                      <div className="column">
                        <h2 className="has-text-weight-bold"
                        style={{
                          lineHeight: '1.2em',
                          fontSize: '40px',
                          textAlign: 'center'
                        }}
                        >Built for marketers like you.</h2>
                      </div>
                    </div>
                    <div className="columns client-logos">
                      <div className="column is-offset-1 is-10">
                        <IconsGridHeader gridItems={iconsgridheader} />
                      </div>
                    </div>
                  </PreviewCompatibleBgImage>
                  <div className="columns">
                    <div className="column is-6">
                      <h2
                        className="has-text-weight-bold "
                        style={{
                          lineHeight: '1.2em',
                          fontSize: '30px'
                        }}
                      >
                        Catch Up on the Latest AI Marketing News, Trends and Technologies
                      </h2>
                    </div>
                    <div className="column is-offset-2">

                      <div className="cta-btn cta-btn__secondary">
                        <Link to="https://inbound.human.marketing/">VIEW ALL ARTICLES</Link>
                      </div>
                    </div>
                  </div>
                  <section className="section">
                    <div className="container">
                      <div className="content blog-roll-home">
                        <BlogRollHome />
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <VideoModal
      src={mainpitch.herovideo}
      open={modalIsOpen}
      closeModal={() => setModalIsOpen(false)}
    />
  </>
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  mainpitch: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    button: PropTypes.string,
    heroimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    herovideo: PropTypes.string,
  }),
  casestudy: PropTypes.array,
  iconsgridbg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  iconsgridheader: PropTypes.shape({
    icons: PropTypes.array,
  }),
  helmet: PropTypes.object,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <div className="dark-bg-hero index-wrapper">
      <Layout>
        <IndexPageTemplate
          {...frontmatter}
          helmet={
            <Helmet titleTemplate="%s | Human">
              <title>{`${frontmatter.title}`}</title>
              <meta name="description" content={`${frontmatter.metadescription}`} />
            </Helmet>
          }
        />
      </Layout>
    </div>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        metadescription
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mainpitch {
          heading
          description
          button
          heroimage {
            childImageSharp {
              fluid(maxWidth: 800, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          herovideo
        }
        casestudy {
          header
          image {
            childImageSharp {
              fluid(maxWidth: 450, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          text
          linktitle
          linkurl
        }
        iconsgridbg {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        iconsgridheader {
          icons {
            header
            image {
              childImageSharp {
                fluid(maxWidth: 100, quality: 64) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            text
          }
        }
      }
    }
  }
`
